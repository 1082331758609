@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"








































































































































































































































































































.assessment__results
  padding: 64px 0px
  width: 100%
  box-sizing: border-box
  display: grid
  grid-gap: 32px

  +mq-m
    padding: 32px 0

  +mq-s
    padding: 24px 0
    grid-gap: 24px

  &--final
    grid-template-columns: repeat(3, 1fr)

    +mq-m
      grid-template-columns: repeat(2, 1fr)
      padding: 32px 0

    +mq-s
      grid-template-columns: repeat(1, 1fr)
      padding: 24px 0
      grid-gap: 24px

  &.--loading
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: calc(100vh - 56px)
